<script>
import FormGroup from '@maison/components/form/FormGroup.vue';
import TextField from '@maison/components/form/fields/TextField.vue';
import { showModal } from '@phoenix/helpers/modal-helper';
import { set, uniqueId } from 'lodash-es';
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    PhoenixForm: defineAsyncComponent(() => import('@maison/components/form/PhoenixForm.vue')),
    FormGroup,
    TextField,
  },

  props: {
    dataModalId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      hasTwoColumns: false,
      fields: {
        email: {
          id: uniqueId('email_'),
          name: 'email',
          placeholder: this.$t('Form.label.email'),
          value: '',
          required: true,
          type: 'email',
        },
      },
    };
  },

  computed: {
    dataModalProps() {
      return JSON.stringify({
        email: this.fields.email.value,
      });
    },
  },

  methods: {
    async submitEmail() {
      const result = await this.$refs.phoenixForm.submit();
      if (result) {
        showModal(this.dataModalId, { email: this.fields.email.value });
      }
    },
  },
};
</script>

<template>
  <phoenix-form
    ref="phoenixForm"
    v-model="fields"
    :naked="true"
    :prefill-enabled="true"
    :should-reset-form-on-submit="true"
  >
    <div class="newsletter-block__form">
      <form-group :attrs="fields.email" :floating-label="false">
        <text-field v-model="fields.email.value" />
      </form-group>
      <button type="button" class="btn btn--primary" @click="submitEmail">
        {{ $t('newsletter.modal.push.cta.label') }}
      </button>
    </div>
  </phoenix-form>
</template>
